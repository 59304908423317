import React, { useState, useEffect } from "react";
import "./ContentSchedule.css"; 
import { allAccounts } from "./Services/Facebook/GET/GetAccounts";
import ContentScheduleForm from "./components/ContentSchedule/ContentScheduleForm";

function ContentSchedule() {
  const [pages, setPages] = useState([]);
  const [pageAccessToken, setPageAccessToken] = useState("");
  const [pageId, setPageId] = useState("");
  const [pageName, setPageName] = useState("");
  const [pageSelected, setPageSelected] = useState(false);

  const getData = async () => {
    const response = await allAccounts();
    console.log(response);

    if (response.status === 200) {
      setPages(response.data.data);
    }
  };
  useEffect(() => {
    getData();
  }, []);


  const handlePageInfo = (acessToken, pageId, pageName) => {
    setPageAccessToken(acessToken);
    setPageId(pageId);
    setPageName(pageName);
    setPageSelected(true);
  }

  return (
    <>
      {pages.length > 0 && pageSelected === false ? (
        <>
          <p>We found {pages.length} pages linked with this profile!</p>
          <div className="pages-container">
            {pages.map((item) => {
              return (
                <>
                  <div onClick={() => handlePageInfo(item.access_token, item.id, item.name)}>
                    <h4>{item.name}</h4>
                    <p>{item.category}</p>
                  </div>
                </>
              );
            })}
          </div>
        </>
      ) : null}


      {pageSelected && (<ContentScheduleForm pageAccessToken={pageAccessToken} pageId={pageId} pageName={pageName}/>)}
    </>
  );
}

export default ContentSchedule;
