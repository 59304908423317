import React, { useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { API } from "./Config/config";
import { NotificationContainer, NotificationManager } from "react-notifications";
import "react-notifications/lib/notifications.css";
import Select, { components } from "react-select";

// Define the categories and niches
const categoriesAndNiches = {
  Fitness: ["Weightlifting", "Yoga", "Running", "Functional fitness"],
  "Business/Entrepreneurship": ["Startups", "Freelancing", "Marketing strategies", "Personal branding"],
  Lifestyle: ["Minimalism", "Productivity hacks", "Home organization", "Parenting tips"],
  Travel: ["Budget travel", "Luxury travel", "Solo travel", "Family travel"],
  Education: ["Online courses", "Language learning", "Study techniques", "Academic writing"],
  Tech: ["Programming tutorials", "Gadget reviews", "AI tools", "Cybersecurity tips"],
  Gaming: ["Esports", "Game streaming", "Retro gaming", "Indie games"],
  Fashion: ["Streetwear", "Sustainable fashion", "High-end brands", "DIY fashion"],
  "Food & Cooking": ["Vegan recipes", "Baking", "Meal prepping", "World cuisines"],
  Wellness: ["Mental health awareness", "Meditation", "Holistic healing", "Self-care routines"],
  Finance: ["Personal finance", "Investing", "Cryptocurrency", "Budgeting"],
};

const customStyles = {
  option: (provided, state) => ({
    ...provided,
    color: 'black',
    backgroundColor: state.isSelected ? '#e0e0e0' : 'white',
    '&:hover': {
      backgroundColor: '#f0f0f0',
    },
  }),
  singleValue: (provided) => ({
    ...provided,
    color: 'black',
  }),
};

function Registration() {
  const navigate = useNavigate();

  const userNameRef = useRef();
  const emailRef = useRef();
  const passwordRef = useRef();
  const confirmPasswordRef = useRef();

  const [selectedCategory, setSelectedCategory] = useState(null);
  const [selectedNiche, setSelectedNiche] = useState(null);
  const [nicheOptions, setNicheOptions] = useState([]);

  const categoryOptions = Object.keys(categoriesAndNiches).map(category => ({
    value: category,
    label: category
  }));

  const handleCategoryChange = (selectedOption) => {
    setSelectedCategory(selectedOption);
    setSelectedNiche(null);
    if (selectedOption) {
      const niches = categoriesAndNiches[selectedOption.value];
      setNicheOptions(niches.map(niche => ({ value: niche, label: niche })));
    } else {
      setNicheOptions([]);
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    const payload = {
      userName: userNameRef.current.value,
      email: emailRef.current.value,
      password: passwordRef.current.value,
      category: selectedCategory?.value,
      niche: selectedNiche?.value,
    };

    if (passwordRef.current.value !== confirmPasswordRef.current.value) {
      NotificationManager.error("Passwords do not match!");
    } else if (!selectedCategory || !selectedNiche) {
      NotificationManager.error("Please select both a category and a niche");
    } else {
      axios
        .post(`${API.apiUrl}/register`, payload)
        .then((res) => {
          console.log(res);
          navigate("/login");
        })
        .catch((e) => {
          console.log(e);
          NotificationManager.error("Something went wrong!");
        });
    }
  };

  return (
    <div className="register-container">
      <NotificationContainer />
      <form className="register-form" onSubmit={handleSubmit}>
        <h2>Register</h2>
        <input
          type="text"
          name="username"
          placeholder="Username"
          ref={userNameRef}
          required
        />
        <input
          type="email"
          name="email"
          placeholder="Email"
          ref={emailRef}
          required
        />
        <input
          type="password"
          name="password"
          placeholder="Password"
          ref={passwordRef}
          required
        />
        <input
          type="password"
          name="confirmPassword"
          placeholder="Confirm Password"
          ref={confirmPasswordRef}
          required
        />
        <p>Choose a category: </p>
        <Select
          value={selectedCategory}
          onChange={handleCategoryChange}
          options={categoryOptions}
          className="select"
          styles={customStyles}
        />
        <p>Choose a niche: </p>
        <Select
          value={selectedNiche}
          onChange={setSelectedNiche}
          options={nicheOptions}
          className="select"
          isDisabled={!selectedCategory}
          styles={customStyles}
        />
        <div style={{ textAlign: "center" }}>
          <button type="submit">Register</button>
          <button type="button" onClick={() => navigate("/login")}>
            Log in
          </button>
        </div>
      </form>
    </div>
  );
}

export default Registration;

