import React, { useState, useEffect } from 'react';
import axios from 'axios';

const INSTAGRAM_CLIENT_ID = '483724304815666';
const REDIRECT_URI = 'https://localhost:3000/auth/instagram/callback';

const InstagramLogin = () => {
  const [accessToken, setAccessToken] = useState(null);
  const [userInfo, setUserInfo] = useState(null);

  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);
    const code = urlParams.get('code');

    if (code) {
      exchangeCodeForToken(code);
    }
  }, []);

  const handleLogin = () => {
    const authUrl = `https://api.instagram.com/oauth/authorize?client_id=${INSTAGRAM_CLIENT_ID}&redirect_uri=${REDIRECT_URI}&scope=basic&response_type=code`;
    window.location.href = authUrl;
  };

  const exchangeCodeForToken = async (code) => {
    try {
      const response = await axios.post('http://localhost:8080/api/instagram/exchange-token', { code });
      setAccessToken(response.data.access_token);
      fetchUserInfo(response.data.access_token);
    } catch (error) {
      console.error('Error exchanging code for token:', error);
    }
  };

  const fetchUserInfo = async (token) => {
    try {
      const response = await axios.get(`https://graph.instagram.com/me?fields=id,username&access_token=${token}`);
      setUserInfo(response.data);
    } catch (error) {
      console.error('Error fetching user info:', error);
    }
  };

  return (
    <div className="flex flex-col items-center justify-center min-h-screen bg-gray-100">
      {!accessToken ? (
        <button
          onClick={handleLogin}
          className="px-4 py-2 font-bold text-white bg-blue-500 rounded hover:bg-blue-700"
        >
          Login with Instagram
        </button>
      ) : (
        <div className="p-4 bg-white rounded shadow">
          <h2 className="mb-4 text-2xl font-bold">Welcome, {userInfo?.username}!</h2>
          <p>Your Instagram ID: {userInfo?.id}</p>
        </div>
      )}
    </div>
  );
};

export default InstagramLogin;

